// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:64e0f33f-d0a3-477d-83a9-5b49dc47ee32",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_V7z7Iw26v",
    "aws_user_pools_web_client_id": "5fvm8ve25kfg9m0vdgrpbr7pfv",
    "oauth": {
        "domain": "uploads-local.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/",
        "redirectSignOut": "http://localhost:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "fileuploads-local",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_content_delivery_bucket": "fileuploads.biz-architect.com-local",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://dr9mt6n5ytnss.cloudfront.net"
};


export default awsmobile;
