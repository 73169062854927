// src/App.js

// import useEffect hook
import React, { useEffect, Component } from 'react';
import './App.css';

// import Hub
import Amplify, { Auth, Hub, Storage } from 'aws-amplify'

import axios from 'axios';
import {Progress} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ConfirmSignIn, ConfirmSignUp, ForgotPassword, RequireNewPassword, SignIn, SignUp, VerifyContact, withAuthenticator } from 'aws-amplify-react';

import awsconfig from './aws-exports';
Amplify.configure(awsconfig);

const previewStyle ={
    width: 320,
    height: 320,
    objectFit: 'cover',
    borderRadius: '50%'
}

class App extends Component {
    constructor(props) {
	super(props);

	document.title = 'File Upload';
	
	this.state = {
            selectedFile: [],
            loaded:0
	}
    }
    
    uploadFile = (file) => {
	const name = this.state.user + '_' + file.name;
	
	Storage.put(name, file, {
	    progressCallback: progress => 
		this.setState({
		    loaded: (progress.loaded/progress.total*100),
		})
	}).
	    then(() => {
		toast.success('Upload success');
		this.setState({ file: name });
	    }). catch (err => {
		toast.error('Upload fail');
	    })
    }


    componentDidMount = () => {
	Auth.currentAuthenticatedUser().then((user) => {
	    this.state.user=(user.username);
	}
    )};

    signOut = () => {
	Auth.currentAuthenticatedUser().then((user) => {
	    
	}
    )};

    
    checkMimeType=(event)=>{
	//getting file object
	let files = event.target.files 
	//define message container
	let err = []
	// list allow mime type
	const types = ['image/png', 'image/jpeg', 'image/gif']
	// loop access array
	for(var x = 0; x<files.length; x++) {
	    // compare file type find doesn't matach
            if (types.every(type => files[x].type !== type)) {
		// create error message and assign to container   
		err[x] = files[x].type+' is not a supported format\n';
	    }
	};
	for(var z = 0; z<err.length; z++) {// if message not same old that mean has error 
            // discard selected file
            toast.error(err[z])
            event.target.value = null
	}
	return true;
    }
    maxSelectFile=(event)=>{
	let files = event.target.files
        if (files.length > 3) { 
            const msg = 'Only 3 images can be uploaded at a time'
            event.target.value = null
            toast.warn(msg)
            return false;
	}
	return true;
    }
    checkFileSize=(event)=>{
	let files = event.target.files
	let size = 2000000 
	let err = []; 
	// for(var x = 0; x<files.length; x++) {
	//     if (files[x].size > size) {
	// 	err[x] = files[x].type+'is too large, please pick a smaller file\n';
	//     }
	// };
	for(var z = 0; z<err.length; z++) {// if message not same old that mean has error 
	    // discard selected file
	    toast.error(err[z])
	    event.target.value = null
	}
	return true;
    }
    onChangeHandler=event=>{
	var files = event.target.files
	if(this.maxSelectFile(event) && // this.checkMimeType(event) && 
		this.checkFileSize(event)){ 
	    // if return true allow to setState
	    this.setState({
		selectedFile: files,
		loaded:0
	    })
	}
    }
    
    onClickHandler = () => {
	const data = new FormData() 
	for(var x = 0; x<this.state.selectedFile.length; x++) {
	    this.uploadFile(this.state.selectedFile[x]);
	}
	
    }    

    render() {
	return (
	<div class="container">
		<img src="/logo.png"/>
		<div class="row">
      	    <div class="offset-md-3 col-md-6">
		<div class="form-group files">
                <label>Upload Your Files </label>
                <input type="file" class="form-control" multiple onChange={this.onChangeHandler}/>
		</div>  
  	    <div class="form-group">
		<ToastContainer />
 <Progress max="100" color="success" value={this.state.loaded} >{Math.round(this.state.loaded,2) }%</Progress>		
  	    </div>
 		<button type="button" class="btn btn-success btn-block" onClick={this.onClickHandler}>Upload</button>


	      <div className="App-footer">
              <a 
            className="cursor-pointer"
	    
            onClick={() => Auth.signOut()}
		>
		<div id="right">
                Sign Out
	    </div>
              </a>
            </div>

	    

	    </div>
	  </div>
	</div>
    );
  }
}
// export default withAuthenticator(App, false, [], null, MyTheme);

// This is my custom Sign in component
class MySignIn extends SignIn {
    showComponent() {
	
	document.title = 'File Upload';

	return (
	    <div class="container">
		<img src="/logo.png"/>
		
		<div class="row">
		<div class="Form__formSection___1PPvW">
		<div data-test="sign-in-header-section" class="Section__sectionHeader___2djyg">
		<span>Sign in to your account</span>
		</div>
      <div className="mx-auto w-full max-w-xs">
        <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <div className="mb-4">
            <label
              className="block text-grey-darker text-sm font-bold mb-2"
              htmlFor="username"
            >
              Username
            </label>
	    
            <input
            id="username"
	    data-test="username-input" class="Input__input___3e_bf"
              key="username"
              name="username"
              onChange={this.handleInputChange}
            type="text"
	    placeholder="Enter your username" 
            />
           </div>
          <div className="mb-6">
            <label
              className="block text-grey-darker text-sm font-bold mb-2"
              htmlFor="password"
            >
              Password
            </label>
            <input
              id="password"
	    data-test="username-input" class="Input__input___3e_bf"

            key="password"
              name="password"
              onChange={this.handleInputChange}
              type="password"
       	    placeholder="Enter your password" 
            />

	      <div className="App-footer">
              Forgot your password?{" "}
              <a 
                className="cursor-pointer"
                onClick={() => super.changeState("forgotPassword")}
		>
		<div id="right">
                Reset Password
	    </div>
              </a>
            </div>
		</div>
		
          <div className="flex items-center justify-between">
		<button
	    
              className="Button__button___vS7Mv"
              type="button"
              onClick={() => super.signIn()}
            >
              Login
            </button>
          </div>
        </form>
      </div>
</div>		  
</div>		  
</div>
	);
  }
}

export default withAuthenticator(App, false, [
  <MySignIn/>,
  <ConfirmSignIn/>,
  <VerifyContact/>,
  <SignUp/>,
  <ConfirmSignUp/>,
  <ForgotPassword/>,
  <RequireNewPassword />
]);
